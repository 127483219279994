import {createContext, useContext, useEffect, useState} from "react";
import React from 'react'
import {oidcClient, userManager} from "../../authConfig";
import {User} from "oidc-client-ts";
import {useNavigate} from "react-router-dom";

// @ts-ignore
export const AuthContext = createContext<AuthContextType>({
  userLoggedIn: false,
  currentUser: null
});

export function useAuth() {
  return useContext(AuthContext);
}

type AuthContextType = {
  userLoggedIn: boolean,
  currentUser: User | null,
  doUsernamePasswordLogin: (username: string, password: string) => Promise<User|null>,
  doRedirectLogin: (urlState: string) => Promise<void>,
  doLogout: () => Promise<boolean>
  doCallback: () => Promise<User>
};

// @ts-ignore
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState<User|null>(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigator = useNavigate()

  useEffect(() => {
    (async () => {
      //const session = userManager.querySessionStatus().then(console.log)
      let user = await userManager.getUser();
      if (user != null && user.expired) {
        try {
          user = await userManager.signinSilent()
        } catch (e) {
          console.log("token can't be refreshed", e)
        }
      }
      if (user && !user.expired) {
        initializeUser(user)
      }
      else initializeUser(null)
    })()
  }, []);

  async function doLogout(): Promise<boolean> {
    userManager.signoutSilent(); // should await?
    initializeUser(null)
    return true
  }

  async function doUsernamePasswordLogin(username: string, password: string ): Promise<User|null> {
    const user = await userManager.signinResourceOwnerCredentials({
        username: username,
        password: password
      });

    initializeUser(user)

    return user
  }

  async function doRedirectLogin(urlState: string): Promise<void> {
    const url = await oidcClient.createSigninRequest({"url_state": urlState, extraQueryParams: {"provider_": "google"}})
    // url.url.replace("https://auth.evo1.com", "http://localhost:7001")
    // window.location.href = url.url.replace("https://auth.evo1.com", "http://localhost:7001")
    return userManager.signinRedirect({"url_state": urlState, extraQueryParams: {"provider": "google"}})
  }

  async function doCallback(): Promise<User> {
    const user = await userManager.signinRedirectCallback()
    initializeUser(user)
    return user
  }

  function initializeUser(user: User | null) {
    if (user) {
      setCurrentUser(user);
      setUserLoggedIn(true);
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
    }

    setLoading(false);
  }

  const value: AuthContextType = {
    userLoggedIn,
    currentUser,
    doUsernamePasswordLogin,
    doRedirectLogin,
    doLogout,
    doCallback
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
