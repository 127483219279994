import React from 'react';
import './shakeable.css'; // Keep the same CSS file

interface ShakeableProps {
    shake: boolean;
    children: React.ReactNode;
}

const Shakeable: React.FC<ShakeableProps & React.HTMLAttributes<HTMLDivElement>>
    = ({
           shake,
           children,
           ...props
       }) => {
    return (
        <div className={shake ? 'shake' : ''} {...props}>
            {children}
        </div>
    );
};

export default Shakeable;
