
import Register from "./components/auth/register/register";

import Header from "./components/header/header";
import Home from "./components/home/home";

import { useRoutes } from "react-router-dom";
import {AuthProvider} from "./contexts/authContext/auth-context";
import {Login} from "./components/auth/login/login";
import Callback from "./components/callback";

function App() {
  const routesArray = [
    {
      path: "*",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/callback",
      element: <Callback />,
    },
  ];
  let routesElement = useRoutes(routesArray);
  return (
    <AuthProvider>
      <Header />
      <div className="w-full h-screen flex flex-col">{routesElement}</div>
    </AuthProvider>
  );
}

export default App;
