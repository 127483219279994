import React, {useEffect, useState} from 'react'
import {useAuth} from '../../contexts/authContext/auth-context'
import {useRestClient} from "../../services/fetch-with-auth";


const Home = () => {
    const { currentUser } = useAuth()

    const [data, setData] = useState({});

    const restClient = useRestClient()

    useEffect(() => {
            (async () => {
                try {
                    const response = await restClient.fetchWithToken('/api/data', {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const jsonData = await response.json();
                    setData(jsonData);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    // Handle error, e.g., display an error message to the user
                }
            })()
    }, [currentUser]);

    return (
        currentUser && <div
            className='text-2xl font-bold pt-14'>Hello {currentUser?.profile ? currentUser.profile.name : currentUser.email},
            you are now logged in. Token expires { /* JSON.stringify(currentUser) */ new Date(currentUser.expires_at * 1000).toString() }
            email: { currentUser.profile?.emailVerified ? 'verified' : 'not verified'  }
        </div>
    )
}

export default Home
