import {useAuth} from "../contexts/authContext/auth-context";

export const useRestClient = () => {
    const { currentUser } = useAuth();

    const fetchWithToken = async (url: string, options: RequestInit = {}): Promise<Response> => {

        // If the user is not logged in or the token is expired, try to refresh it
        if (!currentUser || currentUser.expired) {
            // currentUser = await userManager.signinSilent();
        }

        if (!currentUser || currentUser.expired) {
            // If still not valid, redirect to login
            // await userManager.signinRedirect();
            // throw new Error('User not authenticated');
        }

        // Add the access token to the request headers
        const headers = new Headers(options.headers || {});
        headers.set('Authorization', `Bearer ${currentUser?.access_token}`);

        // Merge the headers with the provided options
        const updatedOptions = {
            ...options,
            headers,
        };

        // Make the request
        return fetch(url, updatedOptions);
    };

    return { fetchWithToken };
};


export async function sendPostRequest(requestData: any, apiUrl: string): Promise<any> {
    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        console.error("Error sending login request:", error);
        throw error; // Re-throw the error for the caller to handle
    }
}
