// src/authConfig.js
import {OidcClient, UserManager, WebStorageStateStore} from 'oidc-client-ts';

const config = {
    authority: 'https://auth.evo1.com', // Issuer URL
    client_id: '9393fb517750eaede819', // Client ID from Authentik
    redirect_uri: window.location.origin + '/callback', // Redirect URI
    response_type: 'code', // Use Authorization Code Flow
    scope: 'openid profile email', // Scopes
    post_logout_redirect_uri: window.location.origin, // Redirect after logout
    userStore: new WebStorageStateStore({ store: window.localStorage }), // Store tokens in localStorage
    automaticSilentRenew: true, // Automatically renew tokens
    silent_redirect_uri: window.location.origin + '/silent-renew', // Silent renew redirect URI
    loadUserInfo: true, // Load user profile information
};

export const userManager = new UserManager(config);

export const oidcClient = new OidcClient(config);
