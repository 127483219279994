import React, {useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import {useAuth} from "../contexts/authContext/auth-context";

const Callback = () => {
    const { doCallback } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        (async ()=> {
            try {
                const user = await doCallback()

                if (user) {
                    const state = user.url_state ?? '/home'
                    console.log(`redirecting to ${state}`)
                    navigate('/home') // should redirect to the intended page
                }

                else {
                    alert('User not found')
                }
            }
            catch (error) {
                alert(error)
            }
        })()
    }, [navigate, doCallback]);

    return (
       <div>
            Logging In
        </div>
    )
}

export default Callback
